const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#3a5ba0",
    color: "white",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "300px",
  },
  inputField: {
    width: "100%",
    padding: "10px",
    margin: "10px 0",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
  },
  emailContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
  },
  emailInput: {
    flex: 1,
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
  },
  emailDomain: {
    marginLeft: "10px",
    fontSize: "16px",
    color: "white",
  },
  button: {
    width: "100%",
    padding: "10px",
    margin: "10px 0",
    border: "none",
    borderRadius: "20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#fff",
    color: "#3a5ba0",
    fontWeight: "bold",
  },
  emailButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: "10px",
  },
  errorMessage: {
    color: "red",
    fontSize: "14px",
  },
  link: {
    textDecoration: "none",
    color: "#3a5ba0",
    fontWeight: "bold",
    backgroundColor: "#fff",
    padding: "10px 20px",
    borderRadius: "20px",
    marginTop: "10px",
  },
};

export default styles;
